import { FlexColumn, FlexRow } from "Components/Flex";
import {
  arrayRemove,
  arrayUnion,
  doc,
  DocumentReference,
  writeBatch,
} from "firebase/firestore";
import { useArtistGroupsByIdsQuery } from "hooks";
import { useArtistGroupsSearch } from "hooks/useArtistGroupsSearch";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { SvgCheckCircle } from "melodies-source/Svgs/CheckCircle";
import { Body1 } from "melodies-source/Text";
import { addToast } from "melodies-source/Toast";
import { SetLiveEvent } from "models/event";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { getArtistImageById } from "Utils/getArtistImageById";
import { ArtistGroupCard } from "./ArtistGroupCard";
import { ArtistGroupOption } from "./ArtistGroupOption";
import { useArtistContext } from "Components/ArtistProvider";
import { firestore } from "firebase-internal";
import { ToastType } from "react-hot-toast";
import { Spinner } from "melodies-source/Spinner";

interface EditAdditionalGroupsProps {
  eventId: string;
  open: boolean;
  onUpdate?: (id: string, data: Partial<SetLiveEvent>) => void;
  onClose: () => void;
}

export const EditAdditionalGroups = ({
  eventId,
  open,
  onUpdate,
  onClose,
}: EditAdditionalGroupsProps) => {
  const [text, setText] = useState("");
  const [remove, setRemove] = useState<{ id: string; name: string }>();
  const [isProcessing, setIsProcessing] = useState(false);
  const { search } = useArtistGroupsSearch();
  const { logAction } = useArtistContext();

  const [event] = useDocumentData<SetLiveEvent, "id", "ref">(
    doc(
      firestore,
      `set_fresh_events/${eventId}`,
    ) as DocumentReference<SetLiveEvent>,
    {
      idField: "id",
      refField: "ref",
    },
  );

  const additionalGroups = event?.additionalGroups ?? [];

  const { artistGroups } = useArtistGroupsByIdsQuery({
    ids: additionalGroups,
    limit: 100,
  });

  const showToast = (msg: string, variant: ToastType = "success") =>
    addToast(msg, variant, { icon: <SvgCheckCircle /> });

  const changeArtists = async (
    artist: { id: string; name: string },
    action: "add" | "remove",
  ) => {
    setIsProcessing(true);
    const arrayOperation = action === "add" ? arrayUnion : arrayRemove;
    try {
      const batch = writeBatch(firestore);
      // any changes to additional groups must be made to both the builder and event doc
      batch.update(doc(firestore, `set_fresh_builder_events/${eventId}`), {
        additionalGroups: arrayOperation(artist.id),
      });
      batch.update(event.ref, {
        additionalGroups: arrayOperation(artist.id),
      });
      await batch.commit();

      await logAction(`portal_${action}_artist`, {
        artistId: artist.id,
        event: event.id,
      });

      showToast(`${artist.name} ${action === "add" ? "added" : "removed"}`);

      onUpdate?.(event.id, {
        additionalGroups:
          action === "add"
            ? additionalGroups.concat([artist.id])
            : additionalGroups.filter((id) => id !== artist.id),
      });
    } catch (err) {
      console.error(err);
      showToast(`unable to ${action} ${artist.name}`, "error");
    } finally {
      setRemove(undefined);
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Modal
        header="Delete Artist?"
        isOpen={!!remove}
        onClose={() => setRemove(undefined)}
      >
        <FlexColumn gap="20px">
          <Body1>
            Are you sure you want to delete {remove?.name} from this show?
          </Body1>
          <FlexRow gap="20px" xEnd>
            <Button variant="tertiary" onClick={() => setRemove(undefined)}>
              Cancel
            </Button>
            <Button
              disabled={isProcessing}
              loading={isProcessing}
              onClick={async () => await changeArtists(remove, "remove")}
            >
              Delete
            </Button>
          </FlexRow>
        </FlexColumn>
      </Modal>
      <Modal
        header="Add Artist"
        isOpen={open}
        onClose={onClose}
        withBackdropClose={!remove}
      >
        <FlexColumn gap="20px">
          <Body1>
            You can add artists to this show by searching below, or remove
            artists by clicking the delete icon.
          </Body1>
          <AutocompleteAsync
            label="Artist Search"
            placeholder="Type Artist Name..."
            text={text}
            setText={setText}
            customOption={ArtistGroupOption}
            clearOnSelect={true}
            onChange={async (option) =>
              await changeArtists(
                { id: option.value, name: option.label },
                "add",
              )
            }
            getOptions={async (q) => {
              const response = await search({ q, query_by: "name" });
              const documents =
                response?.hits?.map((hit) => hit.document) ?? [];

              return documents
                .filter(({ id }) => id !== event?.groupId)
                .filter(({ id }) => !event?.additionalGroups?.includes(id))
                .map((document) => ({
                  label: document.name,
                  value: document.id,
                  avatarSrc: getArtistImageById(document.id),
                }));
            }}
          />
          {isProcessing && <Spinner />}
          <FlexColumn gap="5px">
            {artistGroups?.map((artistGroup) => (
              <ArtistGroupCard
                key={artistGroup.id}
                artistGroup={artistGroup}
                onDelete={() => setRemove(artistGroup)}
              />
            ))}
          </FlexColumn>
        </FlexColumn>
      </Modal>
    </>
  );
};

import { MutableRefObject, useEffect, useRef, useState } from "react";

export const useDetectSticky = (
  ref?: MutableRefObject<any>,
  observerSettings = { threshold: [1] },
) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref = ref || newRef;

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      observerSettings,
    );

    observer.observe(cachedRef);

    return () => {
      observer.unobserve(cachedRef);
    };
  }, [ref, observerSettings]);

  return { ref, isSticky, setIsSticky };
};
